export default {
    booking_date:'Showing Date',
    trip_start_time:'Showing start Time',
    trip_end_time:'Showing End Time',
    customer_name:'Customer Name',
    car_type:'Vehicle Type',
    vehicle_no:'Vehicle Number',
    pickup_address:'Pickup Address',
    drop_address:'Drop address',
    assign_driver:'Assign Agent',
    booking_status:'Showing Status',
    trip_cost:'Trip cost',
    discount_ammount:'Discount Amount',
    payment_status:'Payment Status',
    payment_mode:'Payment Mode',
    booking_text:'Showings',
    image:'Image',
    name:'Name',
    rate_per_km:'Rate Per Kilometer',
    rate_per_hour:'Rate Per Hour',
    min_fare: 'Minimum Fare',
    convenience_fee:'Convenience Fees',
    convenience_fee_percent:'Convenience Fees (%)',
    today_text:'Today',
    gross_earning:'Potential Gross Commissions',
    admin_convenience_fee:'Our Commission',
    this_month_text:'Month',
    total:'Total',
    real_time_driver_section_text:'Agents Realtime',
    allow_location:'Allow Location for the Realtime Map',
    sign_in:'Sign in',
    email_address:'Email Address',
    password:'password',
    sign_in_error:'Sign In Error. Please check Email and Password.',
    device_type:'Device Type',
    user_type:'User Type',
    title:'Title',
    body:'Body',
    push_notification_title:'Push Notifications',
    promo_name:'Promo Name',
    description:'Description',
    promo_type:'Promo Type',
    promo_discount_value:'Promo Discount value',
    max_limit:'Max Limit Value',
    min_limit:'Minimum Order Count',
    start_date:'Start Date (dd/mm/yyyy)',
    end_date:'End Date (dd/mm/yyyy)',
    promo_usage:'Promo Usage Limit',
    promo_used_by:'Promo Used By',
    promo_offer:'Promo and Offers',
    refferal_bonus:'Referral Bonus',
    submit:'Submit',
    update_failed:'Update Failed',
    first_name:'First Name',
    last_name:'Last Name',
    email:'Email',
    mobile:'Mobile',
    profile_image:'Profile Image',
    vehicle_model:'Vehicle Model',
    account_approve:'Account Approved',
    driver_active:'Agent Active Status',
    lisence_image:'License Image',
    vehicle_number:'Vehicle Number',
    wallet_balance:'Wallet Balance',
    signup_via_refferal:'Signup Via Referral',
    refferal_id:'Referral Id',
    all_user:'All Users',
    notification_sent:' Notifications sent.',
    notification_sent_failed:'Notification sending failed.',
    no_user_match:'No Users Matched',
    no_user_matching:'No users found matching your selection.',
    dashboard_text:'Dashboard',
    user:'Users',
    booking_history:'Showing History',
    promo:'Promos',
    logout:'Log Out',
    driver_earning:'Agent Earning History',
    earning_amount:'Commission Amount',
    driver_name:'Agent Name',
    driver_image:'Agent Image',
    months:'Months',
    year:'Years',
    vehicle_type:'Vehicle Number',
    lisence_no:'License Number',
    booking_discount_earning:'Showing Discount',
    trip_cost_driver_share:'Trip Cost / Agent share',
    Gross_trip_cost:"Gross trip cost",
    Discounts:"Discounts",
    Customer_paid:"Customer paid",
    Profit:"Profit ( Cust paid - Trip Cost )",
    earning_reports:'Earning Reports',
    cash_payment_amount:'Cash payment amount',
    card_payment_amount:'Card payment amount',
    wallet_payment_amount:'Wallet payment amount',
    payment_getway:'Payment Gateway',
    currency_symbol: 'Currency Symbol',
    currency_code: 'Currency Code',
    settings_title:'Settings',
    settings_label1:'Cash',
    settings_label2:'Wallet',
    settings_label3:'Braintree',
    settings_label4:'Stripe',
    payment_modes_title:'Payment Modes'
}
